import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "login-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginPage = _resolveComponent("LoginPage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LoginPage, {
      title: _ctx.title,
      server: _ctx.server,
      clientId: _ctx.clientId,
      clientSecret: _ctx.clientSecret,
      background: _ctx.background,
      onGetResult: _ctx.getResult
    }, null, 8, ["title", "server", "clientId", "clientSecret", "background", "onGetResult"])
  ]))
}