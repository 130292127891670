
import { computed, defineComponent, ref } from "vue";
import { WebsocketCollapse, uuid } from "bankezhan-framework-vue";
import { websocketServer } from "../../service/http";
import { PictureFilled } from "@ant-design/icons-vue";
import ImageCategory from "./ImageCategory.vue";
import ImageList from "./ImageList.vue";
import UpdateImageModal from "./UpdateImageModal.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    WebsocketCollapse,
    PictureFilled,
    ImageCategory,
    ImageList,
    UpdateImageModal,
  },
  setup() {
    const socketId = uuid();
    const server = ref<string>(websocketServer);
    const title = ref<string>("图库管理");
    const subtitle = ref<string>("Image Center");
    const imageList = ref();
    const imageCategory = ref();
    const store = useStore();
    const paneKey = store.state.activeKey;
    const reloadTag = computed(() => {
      return paneKey === store.state.activeKey;
    });
    const updateImageModal = ref();
    return {
      socketId,
      server,
      title,
      subtitle,
      imageList,
      imageCategory,
      reloadTag,
      updateImageModal,
    };
  },
  methods: {
    openUpdateImageModal(
      imageId: string,
      imageName: string,
      importance: number,
      url: string
    ) {
      this.updateImageModal.data.imageId = imageId;
      this.updateImageModal.data.imageName = imageName;
      this.updateImageModal.data.importance = importance;
      this.updateImageModal.data.url = url;
      this.updateImageModal.visible = true;
    },
    reloadImageList(categoryId: string, categoryName: string) {
      this.imageList.request.categoryName = categoryName;
      this.imageList.request.categoryId = categoryId;
      this.imageList.request.keyword = null;
      this.imageList.uploadImageData.categoryId = categoryId;
      this.imageList.activeKeyword = null;
      this.imageList.pagination.current = 1;
      this.imageList.pageIndex = 1;
      this.imageList.getImageList();
    },
    reload(value: boolean) {
      if (value) {
        this.imageCategory.init();
        this.imageList.init();
      }
    },
  },
  watch: {
    reloadTag: function (value: boolean) {
      this.reload(value);
    },
  },
});
