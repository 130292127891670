
import { defineComponent, ref } from "vue";
import { LayoutNav, LayoutTab } from "bankezhan-framework-vue";
import { navsData } from "../data/navs-data";
import { background } from "../data/theme-data";

export default defineComponent({
  components: {
    LayoutNav,
    LayoutTab,
  },
  setup() {
    const navs = navsData;
    const logo = ref<string>("/logo.svg");
    const defaultName = ref<string>("数据驾驶舱");
    return {
      navs,
      logo,
      background,
      defaultName,
    };
  },
});
