import { category, article, station, image } from "./svgs-data";

const navsData = [
  {
    index: 1,
    text: "文章管理",
    tab: "文章管理",
    name: "article",
    sole: true,
    icon: article,
  },
  {
    index: 2,
    text: "图库管理",
    tab: "图库管理",
    name: "image",
    sole: true,
    icon: image,
  },
  {
    index: 3,
    text: "招聘管理",
    tab: "招聘管理",
    name: "station",
    sole: true,
    icon: station,
  },
  {
    index: 4,
    text: "内容分类",
    tab: "内容分类",
    name: "category",
    sole: true,
    icon: category,
  },
];

export { navsData };
