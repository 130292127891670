
import { defineComponent, reactive, ref } from "vue";
import { BankezhanEditor } from "bankezhan-framework-vue";
import {
  UserAddOutlined,
  CloudUploadOutlined,
  SaveOutlined,
} from "@ant-design/icons-vue";
import { post } from "../../service/http";

interface StationVO {
  stationId: string;
  stationName: string;
  count: number;
  address: string;
  mail: string;
  categoryId: string;
  qualification: string;
  responsibility: string;
  personToContact: string;
  phoneToContact: string;
  updateTime: string;
}

interface CategoryVO {
  key: string;
  categoryId: string;
  categoryName: string;
  children?: CategoryVO[];
}

export default defineComponent({
  props: ["socketId", "categoryId", "stationId"],
  components: {
    UserAddOutlined,
    BankezhanEditor,
    CloudUploadOutlined,
    SaveOutlined,
  },
  setup(props) {
    const station = reactive<StationVO>({
      stationId: props.stationId,
      stationName: "",
      count: 0,
      address: "",
      mail: "",
      categoryId: props.categoryId,
      qualification: "",
      responsibility: "",
      personToContact: "",
      phoneToContact: "",
      updateTime: "",
    });
    const userId = localStorage.getItem("userId");
    const title = ref<string>("岗位信息");
    const imageUploadUrl = ref<string>("/api/content-engine/image/upload");
    const getQualification = (value: string) => {
      station.qualification = value;
    };
    const getResponsibility = (value: string) => {
      station.responsibility = value;
    };
    const spinning = ref<boolean>(false);
    const qualificationEditor = ref();
    const responsibilityEditor = ref();
    const stationForm = ref();
    const selectableCategory = ref<CategoryVO[]>([]);
    const selectableCategoryLoading = ref<boolean>(false);
    return {
      station,
      title,
      imageUploadUrl,
      getQualification,
      getResponsibility,
      spinning,
      qualificationEditor,
      responsibilityEditor,
      stationForm,
      userId,
      selectableCategory,
      selectableCategoryLoading,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getSelectableCategory();
      this.getStationDetail();
    },
    async getStationDetail() {
      if (
        this.station.stationId !== undefined &&
        this.station.stationId !== null &&
        this.station.stationId !== ""
      ) {
        this.spinning = true;
        try {
          const res = await post({
            url: "/api/content-engine/station/getStationDetail",
            data: {
              stationId: this.stationId,
              socketId: this.socketId,
            },
          });
          this.station.stationId = res.data.stationId;
          this.station.stationName = res.data.stationName;
          this.station.count = res.data.count;
          this.station.address = res.data.address;
          this.station.categoryId = res.data.categoryId;
          this.station.mail = res.data.mail;
          this.station.personToContact = res.data.personToContact;
          this.station.phoneToContact = res.data.phoneToContact;
          this.qualificationEditor.updateContent(res.data.qualification);
          this.responsibilityEditor.updateContent(res.data.responsibility);
          this.spinning = false;
        } catch (error) {
          this.spinning = false;
        }
      }
    },
    async getSelectableCategory() {
      this.selectableCategoryLoading = true;
      try {
        const res = await post({
          url: "/api/content-engine/category/getCategoryNavigator",
          data: {
            contentType: 5,
          },
        });
        this.selectableCategory = res.data;
        this.selectableCategoryLoading = false;
      } catch (error) {
        this.selectableCategoryLoading = false;
      }
    },
    updateStation() {
      this.stationForm
        .validateFields()
        .then(async () => {
          this.spinning = true;
          const data = {
            stationId: this.station.stationId,
            stationName: this.station.stationName,
            count: this.station.count,
            address: this.station.address,
            categoryId: this.station.categoryId,
            mail: this.station.mail,
            qualification: this.station.qualification,
            responsibility: this.station.responsibility,
            personToContact: this.station.personToContact,
            phoneToContact: this.station.phoneToContact,
            updateById: this.userId,
            socketId: this.socketId,
          };
          try {
            await post({
              url: "/api/content-engine/station/update",
              data: data,
            });
            this.spinning = false;
          } catch (error) {
            this.spinning = false;
          }
        })
        //eslint-disable-next-line
        .catch(() => {});
    },
    createStation() {
      this.stationForm
        .validateFields()
        .then(async () => {
          this.spinning = true;
          const data = {
            stationName: this.station.stationName,
            count: this.station.count,
            address: this.station.address,
            mail: this.station.mail,
            qualification: this.station.qualification,
            responsibility: this.station.responsibility,
            personToContact: this.station.personToContact,
            phoneToContact: this.station.phoneToContact,
            categoryId: this.station.categoryId,
            createById: this.userId,
            socketId: this.socketId,
          };
          try {
            const res = await post({
              url: "/api/content-engine/station/create",
              data: data,
            });
            this.station.stationId = res.data.info;
            this.getStationDetail();
            this.spinning = false;
          } catch (error) {
            this.spinning = false;
          }
        })
        //eslint-disable-next-line
        .catch(() => {});
    },
  },
});
