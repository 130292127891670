const category =
  '<svg t="1657765692100" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5892" width="16" height="16"><path d="M896 832v64H597.333333v-64h298.666667zM192 355.477333l300.373333 132.672-1.621333 375.978667a64.064 64.064 0 0 1-2.346667-1.002667l-258.389333-114.730666A64 64 0 0 1 192 689.898667V355.477333zM896 725.333333v64H597.333333v-64h298.666667z m0-106.666666v64H597.333333v-64h298.666667z m-59.264-259.626667V576h-280.746667l0.362667-86.122667 280.384-130.837333zM540.352 160.853333l258.346667 114.752c9.834667 4.373333 18.197333 11.008 24.533333 19.157334l-297.002667 139.114666-318.784-141.482666a63.914667 63.914667 0 0 1 22.570667-16.789334l258.389333-114.730666a64 64 0 0 1 51.946667 0z" p-id="5893" fill="#0aa679"></path></svg>';

const article =
  '<svg t="1658227529035" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5901" width="16" height="16"><path d="M277.333333 298.666667v490.666666h437.333334v64h-405.333334a96 96 0 0 1-95.893333-91.477333L213.333333 757.333333V298.666667h64z m472.789334-127.786667a64 64 0 0 1 64 64v451.242667a64 64 0 0 1-64 64H319.786667V234.88a64 64 0 0 1 64-64h366.336zM640 298.666667h-192v64h192v-64z" p-id="5902" fill="#0aa679"></path></svg>';

const station =
  '<svg t="1659765776747" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5894" width="16" height="16"><path d="M644.8 581.568l160.64 187.456A64 64 0 0 1 756.842667 874.666667H544v-192h-64v192h-212.842667a64 64 0 0 1-48.597333-105.642667l160.661333-187.434667A254.805333 254.805333 0 0 0 512 618.666667c48.64 0 94.08-13.546667 132.8-37.098667zM512 149.333333c117.824 0 213.333333 95.509333 213.333333 213.333334s-95.509333 213.333333-213.333333 213.333333-213.333333-95.509333-213.333333-213.333333S394.176 149.333333 512 149.333333z" p-id="5895" fill="#0aa679"></path></svg>';

const image =
  '<svg t="1660880487123" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5903" width="16" height="16"><path d="M817.365333 213.333333a64 64 0 0 1 64 64v469.333334a64 64 0 0 1-64 64h-597.333333a64 64 0 0 1-64-64V277.333333a64 64 0 0 1 64-64h597.333333zM746.666667 371.114667c-124.394667-1.322667-210.218667 42.325333-251.882667 131.989333l-2.624 5.845333-2.56 5.653334c-32.981333 70.613333-94.677333 104.618667-190.912 103.04v64c120.234667 1.6 205.312-45.696 249.322667-140.864l2.858666-6.357334 2.304-5.12c30.186667-63.936 92.885333-95.68 193.493334-94.229333z m-397.696-17.237334a42.666667 42.666667 0 1 0 0 85.333334 42.666667 42.666667 0 0 0 0-85.333334z" p-id="5904" fill="#0aa679"></path></svg>';

export { category, article, station, image };
