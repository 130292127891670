
import { defineComponent, reactive, ref } from "vue";
import type { Rule } from "ant-design-vue/es/form";
import { post } from "../../service/http";

interface UpdatePasswordVO {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default defineComponent({
  props: ["socketId"],
  setup() {
    const userId = localStorage.getItem("userId");
    const updatePasswordData = reactive<UpdatePasswordVO>({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    const validateOldPassword = async (_rule: Rule, value: string) => {
      if (value === "" || value === undefined || value === null) {
        return Promise.reject("请输入旧密码");
      } else if (value.length > 64) {
        return Promise.reject("密码长度过长");
      } else {
        return Promise.resolve();
      }
    };
    const validateNewPassword = async (_rule: Rule, value: string) => {
      if (value === "" || value === undefined || value === null) {
        return Promise.reject("请输入新密码");
      } else if (value.length > 64) {
        return Promise.reject("密码长度过长");
      } else {
        return Promise.resolve();
      }
    };
    const validateConfirmPassword = async (_rule: Rule, value: string) => {
      if (value === "" || value === undefined || value === null) {
        return Promise.reject("请确认新密码");
      } else if (value.length > 64) {
        return Promise.reject("密码长度过长");
      } else if (value !== updatePasswordData.newPassword) {
        return Promise.reject("两次输入的密码不一致");
      } else {
        return Promise.resolve();
      }
    };
    const loading = ref<boolean>(false);
    const updateResult = ref<boolean>(false);
    const updateSuccess = ref<boolean>(false);
    return {
      userId,
      updatePasswordData,
      validateOldPassword,
      validateNewPassword,
      validateConfirmPassword,
      loading,
      updateResult,
      updateSuccess,
    };
  },
  methods: {
    async updatePassword() {
      this.loading = true;
      try {
        await post({
          url: "/api/authorization/user/updatePassword",
          data: {
            userId: this.userId,
            oldPassword: this.updatePasswordData.oldPassword,
            newPassword: this.updatePasswordData.newPassword,
            updateById: this.userId,
            socketId: this.socketId,
          },
        });
        this.loading = false;
      } catch (error) {
        this.updateResult = true;
        this.loading = false;
      }
    },
  },
});
