import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d1549c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-layout" }
const _hoisted_2 = { class: "update" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsocketCollapse = _resolveComponent("WebsocketCollapse")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_KeyOutlined = _resolveComponent("KeyOutlined")!
  const _component_LogoutOutlined = _resolveComponent("LogoutOutlined")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_CommentList = _resolveComponent("CommentList")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebsocketCollapse, {
      server: _ctx.websocketServer,
      socketId: _ctx.socketId
    }, null, 8, ["server", "socketId"]),
    _createVNode(_component_a_page_header, {
      class: "page-header",
      title: _ctx.username,
      "sub-title": _ctx.welcome
    }, {
      avatar: _withCtx(() => [
        _createVNode(_component_UserOutlined, { class: "avatar" })
      ]),
      extra: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_KeyOutlined, {
            class: "icon",
            onClick: _ctx.openUpdatePasswordTab
          }, null, 8, ["onClick"]),
          _createVNode(_component_LogoutOutlined, {
            class: "icon",
            onClick: _ctx.openLogoutConfirmModal
          }, null, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["title", "sub-title"]),
    _createVNode(_component_CommentList, {
      ref: "commentList",
      socketId: _ctx.socketId
    }, null, 8, ["socketId"]),
    _createVNode(_component_ConfirmModal, {
      ref: "logoutConfirmModal",
      title: _ctx.logoutConfirmTitle,
      onOk: _ctx.logout
    }, null, 8, ["title", "onOk"])
  ]))
}