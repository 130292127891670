
import { defineComponent, reactive, ref } from "vue";
import {
  WebsocketCollapse,
  uuid,
  getId,
  BankezhanEditor,
} from "bankezhan-framework-vue";
import { websocketServer, post } from "../../service/http";
import { CommentOutlined, SaveOutlined } from "@ant-design/icons-vue";
import { useStore } from "vuex";

interface CommentDetailVO {
  name: string;
  phone: string;
  brief: string;
  response: string;
}

export default defineComponent({
  components: {
    WebsocketCollapse,
    CommentOutlined,
    SaveOutlined,
    BankezhanEditor,
  },
  setup() {
    const socketId = uuid();
    const title = ref<string>("回访记录");
    const store = useStore();
    const panekey = store.state.activeKey;
    const commentId = getId(store.state, panekey, "commentId");
    const subtitle = getId(store.state, panekey, "commentName");
    const detail = reactive<CommentDetailVO>({
      name: subtitle,
      phone: "",
      brief: "",
      response: "",
    });
    const imageUploadUrl = ref<string>("/api/content-engine/image/upload");
    const editor = ref();
    const spinning = ref<boolean>(false);
    const userId = localStorage.getItem("userId");
    return {
      socketId,
      websocketServer,
      title,
      subtitle,
      commentId,
      detail,
      imageUploadUrl,
      editor,
      spinning,
      userId,
    };
  },
  mounted() {
    this.getCommentDetail();
  },
  methods: {
    async getCommentDetail() {
      this.spinning = true;
      try {
        const res = await post({
          url: "/api/content-engine/comment/getCommentDetail",
          data: {
            commentId: this.commentId,
            socketId: this.socketId,
          },
        });
        this.detail.phone = res.data.phone;
        this.detail.brief = res.data.brief;
        this.editor.updateContent(res.data.response);
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    getContent(value: string) {
      this.detail.response = value;
    },
    async updateResponse() {
      this.spinning = true;
      const data = {
        commentId: this.commentId,
        response: this.detail.response,
        updateById: this.userId,
        socketId: this.socketId,
      };
      try {
        await post({
          url: "/api/content-engine/comment/updateResponse",
          data: data,
        });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
  },
});
