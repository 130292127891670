
import { defineComponent } from "vue";
import { WebsocketCollapse, uuid, getId } from "bankezhan-framework-vue";
import { websocketServer } from "../../service/http";
import CreateArticleForm from "./CreateArticleForm.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    WebsocketCollapse,
    CreateArticleForm,
  },
  setup() {
    const socketId = uuid();
    const store = useStore();
    const panekey = store.state.activeKey;
    const categoryId = getId(store.state, panekey, "categoryId");
    const articleId = getId(store.state, panekey, "articleId");
    return {
      socketId,
      websocketServer,
      categoryId,
      articleId,
    };
  },
});
