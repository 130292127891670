
import { defineComponent, ref, reactive } from "vue";
import { customHeaderRow } from "../../service/table-style";
import { post } from "../../service/http";
import { ConfirmModal } from "bankezhan-framework-vue";
import { useStore } from "vuex";
import {
  CommentOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";

interface CommentVO {
  key: string;
  commentId: string;
  name: string;
  phone: string;
  brief: string;
  registryClient: string;
  dataStatus: number;
  updateTime: string;
}

interface DataStatusVO {
  key: string;
  dataStatus?: number;
  name: string;
}

const columns = [
  {
    dataIndex: "name",
    key: "name",
    width: 200,
    ellipsis: true,
  },
  {
    title: "联系方式",
    dataIndex: "phone",
    key: "phone",
    width: 200,
    ellipsis: true,
  },
  {
    title: "需求简述",
    dataIndex: "brief",
    key: "brief",
    ellipsis: true,
  },
  {
    title: "操作",
    key: "operation",
    width: 200,
  },
  {
    title: "内容来源",
    dataIndex: "registryClient",
    key: "registryClient",
    width: 200,
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "dataStatus",
    key: "dataStatus",
    width: 100,
  },
  {
    title: "更新日期",
    dataIndex: "updateTime",
    key: "updateTime",
    width: 200,
  },
];

const dataFilter = ref<DataStatusVO[]>([
  {
    key: "0",
    name: "全部",
  },
  {
    key: "1",
    dataStatus: 1,
    name: "已回复",
  },
  {
    key: "4",
    dataStatus: 4,
    name: "未回复",
  },
]);

export default defineComponent({
  props: ["socketId"],
  components: {
    CommentOutlined,
    CloudUploadOutlined,
    DeleteOutlined,
    ConfirmModal,
  },
  setup() {
    //eslint-disable-next-line
    const doCustomHeaderRow = (column: any) => {
      customHeaderRow(column);
    };
    const comments = ref<CommentVO[]>([]);
    const loading = ref<boolean>(false);
    const total = ref<number>();
    const pageIndex = ref<number>(1);
    const size = ref<number>(20);
    const pagination = reactive({
      current: 1,
      defaultPageSize: size.value,
      total: total,
      showTotal: (total: number, range: Array<number>) => {
        return (
          "当前显示 " + range[0] + " - " + range[1] + " 条，共" + total + "条。"
        );
      },
      onChange: (page: number, pageSize: number) => {
        pagination.current = page;
        pageIndex.value = page;
        size.value = pageSize;
      },
    });
    const keyword = ref<string>();
    const activeDataStatus = ref<number>();
    const confirmDeleteModal = ref();
    const confirmDeleteTitle = ref<string>("确认删除留言");
    const userId = localStorage.getItem("userId");
    const deleteCommentId = ref<string>("");
    const store = useStore();
    return {
      comments,
      columns,
      loading,
      total,
      pageIndex,
      size,
      pagination,
      keyword,
      doCustomHeaderRow,
      dataFilter,
      activeDataStatus,
      confirmDeleteModal,
      confirmDeleteTitle,
      userId,
      deleteCommentId,
      store,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCommentList();
    },
    async getCommentList() {
      this.loading = true;
      const data = {
        pageIndex: this.pageIndex,
        pageSize: this.size,
        keyword: this.keyword,
        dataStatus: this.activeDataStatus,
      };
      try {
        const res = await post({
          url: "/api/content-engine/comment/getCommentList",
          data: data,
        });
        this.total = res.data.total;
        this.comments = res.data.commentListVOList;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    searchKeyword(value: string) {
      this.keyword = value;
      this.pagination.current = 1;
      this.pageIndex = 1;
      this.getCommentList();
    },
    filterData(item: DataStatusVO) {
      this.pagination.current = 1;
      this.pageIndex = 1;
      this.activeDataStatus = item.dataStatus;
      this.getCommentList();
    },
    openDeleteCommentModal(commentId: string) {
      this.deleteCommentId = commentId;
      this.confirmDeleteModal.visible = true;
    },
    async deleteComment() {
      const data = {
        commentId: this.deleteCommentId,
        updateById: this.userId,
        socketId: this.socketId,
      };
      try {
        await post({
          url: "/api/content-engine/comment/delete",
          data: data,
        });
        this.confirmDeleteModal.reset();
        this.getCommentList();
      } catch (error) {
        this.confirmDeleteModal.loading = false;
      }
    },
    openCommentDetailTab(comment: CommentVO) {
      const pane = {
        tab: "回访记录",
        name: "commentDetail",
        sole: false,
        commentId: comment.commentId,
        commentName: comment.name,
      };
      this.store.commit("addPane", pane);
    },
  },
  watch: {
    pageIndex: function () {
      this.getCommentList();
    },
    size: function () {
      this.getCommentList();
    },
  },
});
