import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34315688"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article-layout" }
const _hoisted_2 = { class: "article-content" }
const _hoisted_3 = { class: "article-category" }
const _hoisted_4 = { class: "article-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsocketCollapse = _resolveComponent("WebsocketCollapse")!
  const _component_BookFilled = _resolveComponent("BookFilled")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_ArticleCategory = _resolveComponent("ArticleCategory")!
  const _component_ArticleList = _resolveComponent("ArticleList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebsocketCollapse, {
      socketId: _ctx.socketId,
      server: _ctx.server
    }, null, 8, ["socketId", "server"]),
    _createVNode(_component_a_page_header, {
      class: "page-header",
      title: _ctx.title,
      "sub-title": _ctx.subtitle
    }, {
      avatar: _withCtx(() => [
        _createVNode(_component_BookFilled, { class: "avatar" })
      ]),
      _: 1
    }, 8, ["title", "sub-title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ArticleCategory, {
          ref: "articleCategory",
          onReloadArticleList: _ctx.reloadArticleList
        }, null, 8, ["onReloadArticleList"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ArticleList, {
          ref: "articleList",
          socketId: _ctx.socketId
        }, null, 8, ["socketId"])
      ])
    ])
  ]))
}