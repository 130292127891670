
import { defineComponent } from "vue";
import { WebsocketCollapse, uuid, getId } from "bankezhan-framework-vue";
import { websocketServer } from "../../service/http";
import { useStore } from "vuex";
import StationDetailForm from "./StationDetailForm.vue";

export default defineComponent({
  components: {
    WebsocketCollapse,
    StationDetailForm,
  },
  setup() {
    const socketId = uuid();
    const store = useStore();
    const panekey = store.state.activeKey;
    const categoryId = getId(store.state, panekey, "categoryId");
    const stationId = getId(store.state, panekey, "stationId");
    return {
      socketId,
      websocketServer,
      categoryId,
      stationId,
    };
  },
});
