import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cd4af51a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-view" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = { class: "tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutNav = _resolveComponent("LayoutNav")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LayoutTab = _resolveComponent("LayoutTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayoutNav, {
        navs: _ctx.navs,
        logo: _ctx.logo,
        background: _ctx.background
      }, null, 8, ["navs", "logo", "background"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_LayoutTab, { defaultName: _ctx.defaultName }, {
        DEFAULT: _withCtx(() => [
          _createVNode(_component_router_view, { name: "dashboard" })
        ]),
        _: 1
      }, 8, ["defaultName"])
    ])
  ]))
}