import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72421506"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category-layout" }
const _hoisted_2 = { class: "category-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsocketCollapse = _resolveComponent("WebsocketCollapse")!
  const _component_AppstoreFilled = _resolveComponent("AppstoreFilled")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_CategoryList = _resolveComponent("CategoryList")!
  const _component_CreateCategoryModal = _resolveComponent("CreateCategoryModal")!
  const _component_UpdateCategoryModal = _resolveComponent("UpdateCategoryModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebsocketCollapse, {
      server: _ctx.server,
      socketId: _ctx.socketId,
      onReload: _ctx.reload
    }, null, 8, ["server", "socketId", "onReload"]),
    _createVNode(_component_a_page_header, {
      class: "page-header",
      title: _ctx.title,
      "sub-title": _ctx.subtitle
    }, {
      avatar: _withCtx(() => [
        _createVNode(_component_AppstoreFilled, { class: "avatar" })
      ]),
      extra: _withCtx(() => [
        _createElementVNode("div", {
          class: "create",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCreateCategoryModal(null)))
        }, [
          _createVNode(_component_PlusOutlined, { class: "plus-icon" })
        ])
      ]),
      _: 1
    }, 8, ["title", "sub-title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CategoryList, {
        ref: "content",
        socketId: _ctx.socketId,
        onOpenCreateCategoryModal: _ctx.openCreateCategoryModal,
        onOpenUpdateCategoryModal: _ctx.openUpdateCategoryModal
      }, null, 8, ["socketId", "onOpenCreateCategoryModal", "onOpenUpdateCategoryModal"])
    ]),
    _createVNode(_component_CreateCategoryModal, {
      ref: "createCategoryModal",
      socketId: _ctx.socketId
    }, null, 8, ["socketId"]),
    _createVNode(_component_UpdateCategoryModal, {
      ref: "updateCategoryModal",
      socketId: _ctx.socketId
    }, null, 8, ["socketId"])
  ]))
}