
import { defineComponent } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-CN");

export default defineComponent({
  setup() {
    const locale = zhCN;
    return {
      locale,
    };
  },
});
