import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f8ef153"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-layout" }
const _hoisted_2 = { class: "image-content" }
const _hoisted_3 = { class: "image-category" }
const _hoisted_4 = { class: "image-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsocketCollapse = _resolveComponent("WebsocketCollapse")!
  const _component_PictureFilled = _resolveComponent("PictureFilled")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_ImageCategory = _resolveComponent("ImageCategory")!
  const _component_ImageList = _resolveComponent("ImageList")!
  const _component_UpdateImageModal = _resolveComponent("UpdateImageModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebsocketCollapse, {
      socketId: _ctx.socketId,
      server: _ctx.server,
      onReload: _ctx.reload
    }, null, 8, ["socketId", "server", "onReload"]),
    _createVNode(_component_a_page_header, {
      class: "page-header",
      title: _ctx.title,
      "sub-title": _ctx.subtitle
    }, {
      avatar: _withCtx(() => [
        _createVNode(_component_PictureFilled, { class: "avatar" })
      ]),
      _: 1
    }, 8, ["title", "sub-title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ImageCategory, {
          onReloadImageList: _ctx.reloadImageList,
          ref: "imageCategory"
        }, null, 8, ["onReloadImageList"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ImageList, {
          ref: "imageList",
          socketId: _ctx.socketId,
          onOpenUpdateImageModal: _ctx.openUpdateImageModal
        }, null, 8, ["socketId", "onOpenUpdateImageModal"])
      ])
    ]),
    _createVNode(_component_UpdateImageModal, {
      ref: "updateImageModal",
      socketId: _ctx.socketId
    }, null, 8, ["socketId"])
  ]))
}