import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14825b70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "station-detail-form" }
const _hoisted_2 = { class: "update" }
const _hoisted_3 = { class: "station-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAddOutlined = _resolveComponent("UserAddOutlined")!
  const _component_CloudUploadOutlined = _resolveComponent("CloudUploadOutlined")!
  const _component_SaveOutlined = _resolveComponent("SaveOutlined")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_BankezhanEditor = _resolveComponent("BankezhanEditor")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, { spinning: _ctx.spinning }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_page_header, {
          class: "page-header",
          title: _ctx.title,
          "sub-title": _ctx.station.stationName
        }, {
          avatar: _withCtx(() => [
            _createVNode(_component_UserAddOutlined, { class: "avatar" })
          ]),
          extra: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (
                _ctx.station.stationId === undefined ||
                _ctx.station.stationId === null ||
                _ctx.station.stationId === ''
              )
                ? (_openBlock(), _createBlock(_component_CloudUploadOutlined, {
                    key: 0,
                    class: "icon",
                    onClick: _ctx.createStation
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_SaveOutlined, {
                    key: 1,
                    class: "icon",
                    onClick: _ctx.updateStation
                  }, null, 8, ["onClick"]))
            ])
          ]),
          _: 1
        }, 8, ["title", "sub-title"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_form, {
            model: _ctx.station,
            autocomplete: "off",
            layout: "vertical",
            ref: "stationForm"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: "岗位名称",
                name: "stationName",
                rules: [{ required: true, message: '请输入岗位名称' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.station.stationName,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.station.stationName) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "部门",
                name: "categoryId"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tree_select, {
                    value: _ctx.station.categoryId,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.station.categoryId) = $event)),
                    "tree-data": _ctx.selectableCategory,
                    "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                    "tree-line": true,
                    loading: _ctx.selectableCategoryLoading,
                    "tree-default-expand-all": "",
                    "field-names": {
                children: 'children',
                label: 'categoryName',
                value: 'categoryId',
              }
                  }, null, 8, ["value", "tree-data", "loading"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "招聘人数",
                name: "count"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    class: "input-number",
                    min: 0,
                    precision: 0,
                    value: _ctx.station.count,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.station.count) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "工作地点",
                name: "address",
                rules: [{ required: true, message: '请输入工作地点' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.station.address,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.station.address) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "投递邮箱",
                name: "mail",
                rules: [{ required: true, message: '请输入投递邮箱' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.station.mail,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.station.mail) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "联系人",
                name: "personToContact",
                rules: [{ required: true, message: '请输入联系人' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.station.personToContact,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.station.personToContact) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "联系电话",
                name: "phoneToContact",
                rules: [{ required: true, message: '请输入联系电话' }]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _ctx.station.phoneToContact,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.station.phoneToContact) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "任职资格",
                name: "qualification"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BankezhanEditor, {
                    initContent: _ctx.station.qualification,
                    imageUploadUrl: _ctx.imageUploadUrl,
                    ref: "qualificationEditor",
                    onGetContent: _ctx.getQualification
                  }, null, 8, ["initContent", "imageUploadUrl", "onGetContent"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                label: "岗位职责",
                name: "responsibility"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BankezhanEditor, {
                    initContent: _ctx.station.responsibility,
                    imageUploadUrl: _ctx.imageUploadUrl,
                    ref: "responsibilityEditor",
                    onGetContent: _ctx.getResponsibility
                  }, null, 8, ["initContent", "imageUploadUrl", "onGetContent"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["spinning"]))
}