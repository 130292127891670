import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "update-password-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsocketCollapse = _resolveComponent("WebsocketCollapse")!
  const _component_UpdatePasswordForm = _resolveComponent("UpdatePasswordForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebsocketCollapse, {
      server: _ctx.authorizationServer,
      socketId: _ctx.socketId,
      onReload: _ctx.reload
    }, null, 8, ["server", "socketId", "onReload"]),
    _createVNode(_component_UpdatePasswordForm, {
      socketId: _ctx.socketId,
      ref: "updatePassword"
    }, null, 8, ["socketId"])
  ]))
}