
import { defineComponent, reactive, ref } from "vue";
import { post } from "../../service/http";
import { ConfirmModal } from "bankezhan-framework-vue";
import {
  StarOutlined,
  PaperClipOutlined,
  DeleteOutlined,
  StarFilled,
  PlusOutlined,
  FormOutlined,
} from "@ant-design/icons-vue";

const columns = [
  {
    title: "预览",
    dataIndex: "url",
    key: "url",
    width: 120,
  },
  {
    title: "名称",
    dataIndex: "imageName",
    key: "imageName",
    ellipsis: true,
  },
  {
    title: "",
    dataIndex: "categoryClass",
    key: "categoryClass",
    width: 80,
  },
  {
    title: "",
    key: "operation",
    width: 100,
  },
  {
    title: "状态",
    dataIndex: "dataStatus",
    key: "dataStatus",
    width: 100,
  },
  {
    title: "",
    dataIndex: "importance",
    key: "importance",
    width: 100,
  },
  {
    title: "更新日期",
    dataIndex: "updateTime",
    key: "updateTime",
    width: 200,
  },
];

interface ImageListVO {
  key: string;
  imageId: string;
  imageName: string;
  url: string;
  thumbnailUrl: string;
  importance: number;
  categoryClass: number;
  dataStatus: number;
  updateTime: string;
}

interface ImageCategory {
  categoryId?: string;
  categoryName: string;
  keyword?: string;
}

const data = ref<ImageListVO[]>([]);

const request = ref<ImageCategory>({
  categoryName: "根目录",
});

export default defineComponent({
  props: ["socketId"],
  components: {
    StarOutlined,
    PaperClipOutlined,
    DeleteOutlined,
    StarFilled,
    PlusOutlined,
    FormOutlined,
    ConfirmModal,
  },
  setup(props) {
    const total = ref<number>();
    const activeKeyword = ref<string>("");
    const loading = ref<boolean>(false);
    const pageIndex = ref<number>(1);
    const size = ref<number>(10);
    const pagination = reactive({
      current: 1,
      defaultPageSize: 10,
      total: total,
      showTotal: (total: number, range: Array<number>) => {
        return (
          "当前显示 " + range[0] + " - " + range[1] + " 条，共" + total + "条。"
        );
      },
      onChange: (page: number, pageSize: number) => {
        pagination.current = page;
        pageIndex.value = page;
        size.value = pageSize;
      },
    });
    const userId = localStorage.getItem("userId");
    const uploadHeaders = {
      authorization: "Bearer  " + localStorage.getItem("access_token"),
    };
    const uploadImageUrl = ref<string>("/api/content-engine/image/create");
    const uploadImageData = {
      categoryId: request.value.categoryId,
      createById: userId,
      imageName: "",
      width: 200,
      height: 200,
      socketId: props.socketId,
    };
    const confirmDeleteTitle = ref<string>("确认删除图片");
    const confirmDeleteModal = ref();
    const deleteImageId = ref<string>("");
    return {
      columns,
      data,
      request,
      total,
      activeKeyword,
      loading,
      pageIndex,
      size,
      pagination,
      uploadHeaders,
      uploadImageUrl,
      uploadImageData,
      confirmDeleteTitle,
      confirmDeleteModal,
      deleteImageId,
      userId,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getImageList();
    },
    openUpdateImageModal(record: ImageListVO) {
      this.$emit(
        "openUpdateImageModal",
        record.imageId,
        record.imageName,
        record.importance,
        record.url
      );
    },
    openDeleteImageModal(imageId: string) {
      this.deleteImageId = imageId;
      this.confirmDeleteModal.visible = true;
    },
    async deleteImage() {
      this.loading = true;
      const data = {
        imageId: this.deleteImageId,
        updateById: this.userId,
        socketId: this.socketId,
      };
      try {
        await post({
          url: "/api/content-engine/image/delete",
          data: data,
        });
        this.confirmDeleteModal.reset();
        this.getImageList();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //eslint-disable-next-line
    handleUploadChange(file: any) {
      this.loading = true;
      if (file.file.status === "done") {
        this.getImageList();
      }
    },
    searchKeyword(value: string) {
      this.request.keyword = value;
      this.pagination.current = 1;
      this.pageIndex = 1;
      this.getImageList();
    },
    async getImageList() {
      this.loading = true;
      const data = {
        categoryId: this.request.categoryId,
        keyword: this.request.keyword,
        pageIndex: this.pageIndex,
        pageSize: this.size,
      };
      try {
        const res = await post({
          url: "/api/content-engine/image/getImageList",
          data: data,
        });
        this.total = res.data.total;
        this.data = res.data.imageListVOList;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  watch: {
    pageIndex: function () {
      this.getImageList();
    },
    size: function () {
      this.getImageList();
    },
  },
});
