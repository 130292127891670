import router from "../router/index";
import store from "../store/index";
import { checkToken, keyHttp } from "bankezhan-framework-vue";

const post = (params: object) => {
  const token = localStorage.getItem("access_token");
  if (token !== null) {
    checkToken(token, "/api/authorization").catch(() => {
      store.commit("clearPane");
      router.push("/login");
    });
  } else {
    store.commit("clearPane");
    router.push("/login");
  }
  return keyHttp(params, token);
};

const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("userId");
  localStorage.removeItem("authorities");
  store.commit("clearPane");
  router.push("/login");
};

const websocketServer =
  process.env.VUE_APP_WS_SERVER + "/content-engine/websocket";
const authorizationServer =
  process.env.VUE_APP_WS_SERVER + "/authorization/websocket";

export { post, logout, websocketServer, authorizationServer };
