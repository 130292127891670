import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "station-detail-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsocketCollapse = _resolveComponent("WebsocketCollapse")!
  const _component_StationDetailForm = _resolveComponent("StationDetailForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebsocketCollapse, {
      server: _ctx.websocketServer,
      socketId: _ctx.socketId
    }, null, 8, ["server", "socketId"]),
    _createVNode(_component_StationDetailForm, {
      socketId: _ctx.socketId,
      categoryId: _ctx.categoryId,
      stationId: _ctx.stationId
    }, null, 8, ["socketId", "categoryId", "stationId"])
  ]))
}