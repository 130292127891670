
import { defineComponent, ref } from "vue";
import { post } from "../../service/http";

interface DataItem {
  key?: string;
  categoryId?: string;
  categoryName: string;
  children?: DataItem[];
}

const data = ref<DataItem[]>([
  {
    key: "CONTENT_ENGINE",
    categoryName: "根目录",
  },
]);

const columns = [
  {
    title: "",
    dataIndex: "categoryName",
    key: "categoryName",
    ellipsis: true,
  },
];

export default defineComponent({
  setup() {
    const defaultExpandedRowKeys = ["CONTENT_ENGINE"];
    const loading = ref<boolean>(false);
    const activeId = ref<string>("");
    return {
      defaultExpandedRowKeys,
      loading,
      activeId,
      data,
      columns,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCategoryNavigator();
    },
    reloadStationList(categoryId: string, categoryName: string) {
      this.activeId = categoryId;
      this.$emit("reloadStationList", categoryId, categoryName);
    },
    async getCategoryNavigator() {
      this.loading = true;
      try {
        const res = await post({
          url: "/api/content-engine/category/getCategoryNavigator",
          data: {
            contentType: 5,
          },
        });
        this.data = res.data;
        this.loading = false;
      } catch (error) {
        this.data = [];
        this.loading = false;
      }
    },
  },
});
