
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { LoginPage } from "bankezhan-framework-vue";
import { background } from "../data/theme-data";

export default defineComponent({
  components: {
    LoginPage,
  },
  setup() {
    const title = ref<string>("阿半软件网站管理系统");
    const server = ref<string>("/api/authorization");
    const clientId = ref<string>("BANKEZHAN-FRAMEWORK");
    const clientSecret = ref<string>("INITIAL_PASSWORD");
    const router = useRouter();
    const getResult = function (value: boolean) {
      if (value) {
        router.push("/home");
      }
    };
    return {
      title,
      server,
      clientId,
      clientSecret,
      background,
      router,
      getResult,
    };
  },
});
