
import { defineComponent, reactive, ref } from "vue";
import { BankezhanEditor } from "bankezhan-framework-vue";
import { post } from "../../service/http";
import { TagFilled } from "@ant-design/icons-vue";

interface CreateArticleVO {
  title: string;
  subtitle?: string;
  author: string;
  coverUrl?: string;
  brief?: string;
  content: string;
  importance?: number;
  createById: string;
  categoryId: string;
  socketId: string;
}

export default defineComponent({
  props: ["categoryId", "articleId", "socketId"],
  components: {
    BankezhanEditor,
    TagFilled,
  },
  setup(props) {
    const spinning = ref<boolean>(false);
    const currentArticleId = ref<string>(props.articleId);
    const userId = localStorage.getItem("userId");
    const createArticleData = reactive<CreateArticleVO>({
      title: "",
      author: "",
      content: "",
      createById: userId === null ? "" : userId,
      categoryId: props.categoryId,
      socketId: props.socketId,
    });
    const imageUploadUrl = ref<string>("/api/content-engine/image/upload");
    const getContent = (value: string) => {
      createArticleData.content = value;
    };
    const createArticleForm = ref();
    const editor = ref();
    const dataStatus = ref<number>();
    const updateTime = ref<string>();
    const moreOptions = ref<boolean>(false);
    const uploadHeaders = {
      authorization: "Bearer  " + localStorage.getItem("access_token"),
    };
    const uploadCoverUrlData = {
      createById: userId,
      width: 980,
      height: 200,
    };
    const uploadCoverUrl = ref<string>(
      "/api/content-engine/image/uploadArticleCover"
    );
    return {
      createArticleData,
      getContent,
      imageUploadUrl,
      createArticleForm,
      editor,
      spinning,
      currentArticleId,
      dataStatus,
      updateTime,
      userId,
      moreOptions,
      uploadHeaders,
      uploadCoverUrlData,
      uploadCoverUrl,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getArticleDetail();
    },
    toggleMoreOptions() {
      this.moreOptions = !this.moreOptions;
    },
    removeCoverUrl() {
      this.createArticleData.coverUrl = "";
    },
    //eslint-disable-next-line
    handleUploadChange(file: any) {
      if (file.file.status === "done") {
        const location = file.file.response.location;
        this.createArticleData.coverUrl = location;
      }
    },
    createArticle() {
      this.createArticleForm
        .validateFields()
        .then(async () => {
          this.spinning = true;
          try {
            const res = await post({
              url: "/api/content-engine/article/create",
              data: this.createArticleData,
            });
            this.currentArticleId = res.data.info;
            this.getArticleDetail();
            this.spinning = false;
          } catch (error) {
            this.spinning = false;
          }
        })
        //eslint-disable-next-line
        .catch(() => {});
    },
    async getArticleDetail() {
      if (
        this.currentArticleId !== null &&
        this.currentArticleId !== undefined &&
        this.currentArticleId !== ""
      ) {
        this.spinning = true;
        try {
          const res = await post({
            url: "/api/content-engine/article/getArticleDetail",
            data: {
              articleId: this.currentArticleId,
              socketId: this.socketId,
            },
          });
          this.createArticleData.title = res.data.title;
          this.createArticleData.subtitle = res.data.subtitle;
          this.createArticleData.author = res.data.author;
          this.createArticleData.brief = res.data.brief;
          this.createArticleData.coverUrl = res.data.coverUrl;
          this.editor.updateContent(res.data.content);
          this.createArticleData.importance = res.data.importance;
          this.dataStatus = res.data.dataStatus;
          this.updateTime = res.data.updateTime;
          this.spinning = false;
        } catch (error) {
          this.spinning = false;
        }
      }
    },
    updateArticle() {
      this.createArticleForm
        .validateFields()
        .then(async () => {
          this.spinning = true;
          const data = {
            articleId: this.currentArticleId,
            title: this.createArticleData.title,
            subtitle: this.createArticleData.subtitle,
            author: this.createArticleData.author,
            brief: this.createArticleData.brief,
            coverUrl: this.createArticleData.coverUrl,
            content: this.createArticleData.content,
            importance: this.createArticleData.importance,
            updateById: this.userId,
            socketId: this.socketId,
          };
          try {
            await post({
              url: "/api/content-engine/article/updateArticle",
              data: data,
            });
            this.spinning = false;
          } catch (error) {
            this.spinning = false;
          }
        })
        //eslint-disable-next-line
        .catch(() => {});
    },
  },
});
