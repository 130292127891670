
import { defineComponent, ref } from "vue";
import { customHeaderRow } from "../../service/table-style";
import { post } from "../../service/http";
import {
  SettingOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { ConfirmModal } from "bankezhan-framework-vue";

const columns = [
  {
    title: "",
    dataIndex: "categoryName",
    key: "categoryName",
  },
  {
    title: "类型",
    dataIndex: "typeName",
    key: "typeName",
  },
  {
    title: "操作",
    key: "operation",
  },
  {
    title: "来源",
    dataIndex: "registryClient",
    key: "registryClient",
  },
  {
    title: "标识符",
    dataIndex: "categorySymbol",
    key: "categorySymbol",
  },
  {
    title: "状态",
    dataIndex: "dataStatus",
    key: "dataStatus",
  },
  {
    title: "修改日期",
    dataIndex: "updateTime",
    key: "updateTime",
  },
];

export interface CategoryItem {
  key: string;
  categoryId: string;
  categoryName: string;
  contentType: number;
  typeName: string;
  categorySymbol: string;
  registryClient: string;
  parentId: string;
  dataStatus: number;
  updateTime: string;
  children?: CategoryItem[];
}

const data = ref<CategoryItem[]>([]);

export default defineComponent({
  props: ["socketId"],
  components: {
    PlusOutlined,
    SettingOutlined,
    DeleteOutlined,
    ConfirmModal,
  },
  setup() {
    //eslint-disable-next-line
    const doCustomHeaderRow = (column: any) => {
      customHeaderRow(column);
    };
    const loading = ref<boolean>(false);
    const userId = localStorage.getItem("userId");
    const confirmDeleteTitle = "确认删除分类";
    const deleteCategoryId = ref<string>("");
    const confirmDeleteModal = ref();
    return {
      doCustomHeaderRow,
      columns,
      data,
      loading,
      userId,
      confirmDeleteTitle,
      deleteCategoryId,
      confirmDeleteModal,
    };
  },
  mounted() {
    this.getCategoryTree();
  },
  methods: {
    init() {
      this.getCategoryTree();
    },
    async getCategoryTree() {
      this.loading = true;
      try {
        const res = await post({
          url: "/api/content-engine/category/getCategoryTree",
          data: {},
        });
        this.data = res.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    openCreateCategoryModal(categoryId: string) {
      this.$emit("openCreateCategoryModal", categoryId);
    },
    openUpdateCategoryModal(record: CategoryItem) {
      this.$emit("openUpdateCategoryModal", record);
    },
    openDeleteCategoryModal(categoryId: string) {
      this.deleteCategoryId = categoryId;
      this.confirmDeleteModal.visible = true;
    },
    async deleteCategory() {
      this.loading = true;
      const data = {
        categoryId: this.deleteCategoryId,
        updateById: this.userId,
        socketId: this.socketId,
      };
      try {
        await post({
          url: "/api/content-engine/category/delete",
          data: data,
        });
        this.confirmDeleteModal.reset();
        this.getCategoryTree();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
});
