import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import LoginView from "../views/LoginView.vue";
import HomeView from "../views/HomeView.vue";
import DashboardLayout from "../components/dashboard/DashboardLayout.vue";
import CategoryLayout from "../components/category/CategoryLayout.vue";
import ArticleLayout from "../components/article/ArticleLayout.vue";
import CreateArticleLayout from "../components/article/CreateArticleLayout.vue";
import ArticleCategoryLinkLayout from "../components/article/ArticleCategoryLinkLayout.vue";
import StationLayout from "../components/station/StationLayout.vue";
import StationDetailLayout from "../components/station/StationDetailLayout.vue";
import CommentLayout from "../components/comment/CommentLayout.vue";
import CommentDetailLayout from "../components/comment/CommentDetailLayout.vue";
import UpdatePasswordLayout from "../components/dashboard/UpdatePasswordLayout.vue";
import ImageLayout from "../components/image/ImageLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: HomeView,
    children: [
      {
        path: "",
        components: {
          dashboard: DashboardLayout,
          category: CategoryLayout,
          article: ArticleLayout,
          createArticle: CreateArticleLayout,
          articleCategoryLink: ArticleCategoryLinkLayout,
          station: StationLayout,
          stationDetail: StationDetailLayout,
          comment: CommentLayout,
          commentDetail: CommentDetailLayout,
          updatePassword: UpdatePasswordLayout,
          image: ImageLayout,
        },
      },
    ],
  },
  {
    path: "/login",
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
