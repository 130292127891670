
import { computed, defineComponent, ref } from "vue";
import { post, logout } from "../../service/http";
import {
  UserOutlined,
  LogoutOutlined,
  KeyOutlined,
} from "@ant-design/icons-vue";
import CommentList from "../comment/CommentList.vue";
import { WebsocketCollapse, uuid, ConfirmModal } from "bankezhan-framework-vue";
import { websocketServer } from "../../service/http";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    UserOutlined,
    CommentList,
    WebsocketCollapse,
    LogoutOutlined,
    ConfirmModal,
    KeyOutlined,
  },
  setup() {
    const username = ref<string>("用户");
    const welcome = ref<string>("今天也要开心哦(*^_^*)");
    const userId = localStorage.getItem("userId");
    const socketId = uuid();
    const store = useStore();
    const reloadTag = computed(() => {
      return store.state.activeKey === "DEFAULT";
    });
    const commentList = ref();
    const logoutConfirmModal = ref();
    const logoutConfirmTitle = ref<string>("确认退出账号");
    const updatePassword = ref();
    return {
      username,
      welcome,
      userId,
      socketId,
      websocketServer,
      reloadTag,
      commentList,
      logoutConfirmModal,
      logoutConfirmTitle,
      updatePassword,
      store,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getUserInfo();
    },
    async getUserInfo() {
      try {
        //eslint-disable-next-line
        const res: any = await post({
          url: "/api/authorization/user/getUserInfo",
          data: {
            userId: this.userId,
          },
        });
        this.username = res.data.userName;
      } catch (error) {
        return;
      }
    },
    reload() {
      this.getUserInfo();
      this.commentList.init();
    },
    openLogoutConfirmModal() {
      this.logoutConfirmModal.visible = true;
    },
    openUpdatePasswordTab() {
      const pane = {
        tab: "修改密码",
        name: "updatePassword",
        sole: false,
      };
      this.store.commit("addPane", pane);
    },
    logout() {
      this.logoutConfirmModal.reset();
      logout();
    },
  },
  watch: {
    reloadTag: function (value: boolean) {
      if (value) {
        this.reload();
      }
    },
  },
});
