
import { computed, defineComponent, ref } from "vue";
import { WebsocketCollapse, uuid } from "bankezhan-framework-vue";
import { websocketServer } from "../../service/http";
import { UsergroupAddOutlined } from "@ant-design/icons-vue";
import StationCategory from "./StationCategory.vue";
import StationList from "./StationList.vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    WebsocketCollapse,
    UsergroupAddOutlined,
    StationCategory,
    StationList,
  },
  setup() {
    const server = ref<string>(websocketServer);
    const socketId = uuid();
    const title = ref<string>("招聘管理");
    const subtitle = ref<string>("Station Center");
    const stationList = ref();
    const stationCategory = ref();
    const store = useStore();
    const paneKey = store.state.activeKey;
    const reloadTag = computed(() => {
      return paneKey === store.state.activeKey;
    });
    return {
      server,
      socketId,
      title,
      subtitle,
      stationList,
      stationCategory,
      reloadTag,
    };
  },
  methods: {
    reloadStationList(categoryId: string, categoryName: string) {
      this.stationList.request.categoryName = categoryName;
      this.stationList.request.categoryId = categoryId;
      this.stationList.request.keyword = null;
      this.stationList.activeKeyword = null;
      this.stationList.pagination.current = 1;
      this.stationList.pageIndex = 1;
      this.stationList.getStationList();
    },
    reload(value: boolean) {
      if (value) {
        this.stationCategory.init();
        this.stationList.init();
      }
    },
  },
  watch: {
    reloadTag: function (value: boolean) {
      this.reload(value);
    },
  },
});
