
import { defineComponent, reactive, ref } from "vue";
import { RegistryClient, ContentType } from "./CreateCategoryModal.vue";
import { post } from "../../service/http";

interface FormData {
  categoryId: string;
  categoryName: string;
  categorySymbol: string;
  registryClient: string;
  contentType?: number;
  parentId?: string;
  updateById: string;
  socketId: string;
}

interface ParentCategory {
  key: string;
  categoryId: string;
  categoryName: string;
  parentId?: string;
  children: ParentCategory[];
}

export default defineComponent({
  props: ["socketId"],
  setup(props) {
    const visible = ref<boolean>(false);
    const userId = localStorage.getItem("userId");
    const updateResult = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const data = reactive<FormData>({
      categoryId: "",
      categoryName: "",
      categorySymbol: "",
      registryClient: "",
      updateById: userId === null ? "" : userId,
      socketId: props.socketId,
    });
    const selectableRegistryClient = ref<RegistryClient[]>([]);
    const selectableContentType = ref<ContentType[]>([]);
    const selectableParentCategory = ref<ParentCategory[]>([]);
    const clientLoading = ref<boolean>(false);
    const contentTypeLoading = ref<boolean>(false);
    const parentCategoryLoading = ref<boolean>(false);
    const updateCategoryModal = ref();
    return {
      visible,
      data,
      updateResult,
      loading,
      selectableRegistryClient,
      selectableContentType,
      selectableParentCategory,
      clientLoading,
      contentTypeLoading,
      parentCategoryLoading,
      updateCategoryModal,
    };
  },
  methods: {
    reset() {
      this.loading = false;
      this.updateResult = false;
      this.visible = false;
    },
    async updateCategory() {
      this.loading = true;
      try {
        await post({
          url: "/api/content-engine/category/update",
          data: this.data,
        });
        this.reset();
      } catch (error) {
        this.loading = false;
        this.updateResult = true;
      }
    },
  },
});
