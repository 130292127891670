
import { defineComponent, reactive, ref } from "vue";
import { post } from "../../service/http";
import type { Rule } from "ant-design-vue/es/form";

interface FormData {
  imageId: string;
  imageName: string;
  importance: number;
  url: string;
  updateById: string;
  socketId: string;
}

export default defineComponent({
  props: ["socketId"],
  setup(props) {
    const visible = ref<boolean>(false);
    const updateResult = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const userId = localStorage.getItem("userId");
    const data = reactive<FormData>({
      imageId: "",
      imageName: "",
      importance: 0,
      url: "",
      updateById: userId === null ? "" : userId,
      socketId: props.socketId,
    });
    const validateName = async (_rule: Rule, value: string) => {
      if (value === "" || value === undefined || value === null) {
        return Promise.reject("请输入图片名称");
      } else if (value.length > 128) {
        return Promise.reject("名称长度不能超过60个汉字");
      } else {
        return Promise.resolve();
      }
    };
    return {
      visible,
      updateResult,
      loading,
      data,
      validateName,
    };
  },
  methods: {
    reset() {
      this.loading = false;
      this.updateResult = false;
      this.visible = false;
    },
    async updateImage() {
      this.loading = true;
      try {
        await post({
          url: "/api/content-engine/image/update",
          data: this.data,
        });
        this.reset();
      } catch (error) {
        this.loading = false;
        this.updateResult = true;
      }
    },
  },
});
