
import { computed, defineComponent, ref } from "vue";
import { WebsocketCollapse, uuid } from "bankezhan-framework-vue";
import { useStore } from "vuex";
import ArticleCategory from "./ArticleCategory.vue";
import ArticleList from "./ArticleList.vue";
import { websocketServer } from "../../service/http";
import { BookFilled } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    WebsocketCollapse,
    ArticleCategory,
    ArticleList,
    BookFilled,
  },
  setup() {
    const socketId = uuid();
    const server = ref<string>(websocketServer);
    const title = ref<string>("文章管理");
    const subtitle = ref<string>("Article Center");
    const store = useStore();
    const paneKey = store.state.activeKey;
    const reloadTag = computed(() => {
      return paneKey === store.state.activeKey;
    });
    const articleList = ref();
    const articleCategory = ref();
    return {
      socketId,
      server,
      title,
      subtitle,
      articleList,
      articleCategory,
      reloadTag,
    };
  },
  methods: {
    reloadArticleList(categoryId: string, categoryName: string) {
      this.articleList.request.categoryName = categoryName;
      this.articleList.request.categoryId = categoryId;
      this.articleList.request.keyword = null;
      this.articleList.activeKeyword = null;
      this.articleList.pagination.current = 1;
      this.articleList.pageIndex = 1;
      this.articleList.getArticleList();
    },
    reload(value: boolean) {
      if (value) {
        this.articleCategory.init();
        this.articleList.init();
      }
    },
  },
  watch: {
    reloadTag: function (value: boolean) {
      this.reload(value);
    },
  },
});
