
import { defineComponent, reactive, ref } from "vue";
import { post } from "../../service/http";
import { useStore } from "vuex";
import {
  DeleteOutlined,
  PaperClipOutlined,
  PlusOutlined,
  FormOutlined,
} from "@ant-design/icons-vue";
import { ConfirmModal } from "bankezhan-framework-vue";

interface StationListVO {
  key: string;
  stationId: string;
  stationName: string;
  count: number;
  dataStatus: number;
  categoryClass: number;
  updateTime: string;
}

interface RequestVO {
  categoryId?: string;
  categoryName: string;
  keyword?: string;
}

const columns = [
  {
    title: "岗位",
    dataIndex: "stationName",
    key: "stationName",
    ellipsis: true,
  },
  {
    title: "",
    dataIndex: "categoryClass",
    key: "categoryClass",
    width: 80,
  },
  {
    title: "",
    key: "operation",
    width: 100,
  },
  {
    title: "招聘人数",
    dataIndex: "count",
    key: "count",
  },
  {
    title: "岗位状态",
    dataIndex: "dataStatus",
    key: "dataStatus",
    width: 100,
  },
  {
    title: "更新日期",
    dataIndex: "updateTime",
    key: "updateTime",
    width: 200,
  },
];

const data = ref<StationListVO[]>([]);

const request = reactive<RequestVO>({
  categoryName: "根目录",
});

export default defineComponent({
  props: ["socketId"],
  components: {
    PlusOutlined,
    PaperClipOutlined,
    DeleteOutlined,
    FormOutlined,
    ConfirmModal,
  },
  setup() {
    const total = ref<number>();
    const activeKeyword = ref<string>("");
    const loading = ref<boolean>(false);
    const pageIndex = ref<number>(1);
    const size = ref<number>(20);
    const pagination = reactive({
      current: 1,
      defaultPageSize: 20,
      total: total,
      showTotal: (total: number, range: Array<number>) => {
        return (
          "当前显示 " + range[0] + " - " + range[1] + " 条，共" + total + "条。"
        );
      },
      onChange: (page: number, pageSize: number) => {
        pagination.current = page;
        pageIndex.value = page;
        size.value = pageSize;
      },
    });
    const store = useStore();
    const openCreateStationTab = () => {
      const pane = {
        tab: "[新建]" + request.categoryName,
        name: "stationDetail",
        sole: false,
        categoryId: request.categoryId,
      };
      store.commit("addPane", pane);
    };
    const openUpdateStationTab = (station: StationListVO) => {
      const pane = {
        tab: "[编辑]" + station.stationName,
        name: "stationDetail",
        sole: false,
        stationId: station.stationId,
      };
      store.commit("addPane", pane);
    };
    const confirmDeleteTitle = ref<string>("确认删除岗位");
    const deleteStationId = ref<string>("");
    const confirmDeleteModal = ref();
    const userId = localStorage.getItem("userId");
    return {
      columns,
      data,
      request,
      total,
      activeKeyword,
      loading,
      pagination,
      pageIndex,
      size,
      openCreateStationTab,
      openUpdateStationTab,
      confirmDeleteModal,
      confirmDeleteTitle,
      deleteStationId,
      userId,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getStationList();
    },
    async getStationList() {
      this.loading = true;
      const data = {
        categoryId: this.request.categoryId,
        keyword: this.request.keyword,
        pageIndex: this.pageIndex,
        pageSize: this.size,
      };
      try {
        const res = await post({
          url: "/api/content-engine/station/getStationList",
          data: data,
        });
        this.total = res.data.total;
        this.data = res.data.stationListVOList;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    searchKeyword(value: string) {
      this.request.keyword = value;
      this.pagination.current = 1;
      this.pageIndex = 1;
      this.getStationList();
    },
    openDeleteStationModal(stationId: string) {
      this.deleteStationId = stationId;
      this.confirmDeleteModal.visible = true;
    },
    async deleteStation() {
      this.loading = true;
      const data = {
        stationId: this.deleteStationId,
        updateById: this.userId,
        socketId: this.socketId,
      };
      try {
        await post({
          url: "/api/content-engine/station/delete",
          data: data,
        });
        this.confirmDeleteModal.reset();
        this.getStationList();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  watch: {
    pageIndex: function () {
      this.getStationList();
    },
    size: function () {
      this.getStationList();
    },
  },
});
