const customHeaderCell = () => {
  return {
    style: {
      background: "transparent",
      color: "#0aa679",
      borderBottom: "none",
    },
  };
};

//eslint-disable-next-line
const customHeaderRow = (column: any) => {
  //eslint-disable-next-line
  column.forEach((e: any) => {
    e.customHeaderCell = customHeaderCell;
  });
};

export { customHeaderRow };
