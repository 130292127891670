import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "create-article-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebsocketCollapse = _resolveComponent("WebsocketCollapse")!
  const _component_CreateArticleForm = _resolveComponent("CreateArticleForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_WebsocketCollapse, {
      socketId: _ctx.socketId,
      server: _ctx.websocketServer
    }, null, 8, ["socketId", "server"]),
    _createVNode(_component_CreateArticleForm, {
      categoryId: _ctx.categoryId,
      articleId: _ctx.articleId,
      socketId: _ctx.socketId
    }, null, 8, ["categoryId", "articleId", "socketId"])
  ]))
}