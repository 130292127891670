import { createStore } from "vuex";
import { add, remove, update, clear } from "bankezhan-framework-vue";

export default createStore({
  state: {
    panes: [],
    activeKey: "DEFAULT",
  },
  getters: {},
  mutations: {
    addPane: (state, pane) => {
      add(state, pane);
    },
    removePane: (state, targetKey) => {
      remove(state, targetKey);
    },
    updatePane: (state, activeKey) => {
      update(state, activeKey);
    },
    clearPane: (state) => {
      clear(state);
    },
  },
  actions: {},
  modules: {},
});
