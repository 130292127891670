
import { defineComponent, ref } from "vue";
import { WebsocketCollapse, uuid } from "bankezhan-framework-vue";
import { authorizationServer } from "../../service/http";
import UpdatePasswordForm from "./UpdatePasswordForm.vue";

export default defineComponent({
  components: {
    WebsocketCollapse,
    UpdatePasswordForm,
  },
  setup() {
    const socketId = uuid();
    const updatePassword = ref();
    const reload = () => {
      updatePassword.value.updateSuccess = true;
    };
    return {
      socketId,
      authorizationServer,
      updatePassword,
      reload,
    };
  },
});
