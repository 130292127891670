
import { computed, defineComponent, ref } from "vue";
import CategoryList from "./CategoryList.vue";
import { useStore } from "vuex";
import { WebsocketCollapse, uuid } from "bankezhan-framework-vue";
import CreateCategoryModal from "./CreateCategoryModal.vue";
import UpdateCategoryModal from "./UpdateCategoryModal.vue";
import { websocketServer, post } from "../../service/http";
import { AppstoreFilled, PlusOutlined } from "@ant-design/icons-vue";
import { CategoryItem } from "./CategoryList.vue";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    CategoryList,
    WebsocketCollapse,
    CreateCategoryModal,
    AppstoreFilled,
    PlusOutlined,
    UpdateCategoryModal,
  },
  setup() {
    const socketId = uuid();
    const server = ref<string>(websocketServer);
    const title = ref<string>("分类总览");
    const subtitle = ref<string>("Content Category");
    const store = useStore();
    const paneKey = store.state.activeKey;
    const reloadTag = computed(() => {
      return paneKey === store.state.activeKey;
    });
    const content = ref();
    const createCategoryModal = ref();
    const openCreateCategoryModal = (categoryId: string) => {
      createCategoryModal.value.data.parentId = categoryId;
      createCategoryModal.value.contentTypeLoading = true;
      createCategoryModal.value.clientLoading = true;
      createCategoryModal.value.visible = true;
      post({
        url: "/api/content-engine/category/getSelectableContentType",
      })
        //eslint-disable-next-line
        .then((res: any) => {
          createCategoryModal.value.selectableContentType = res.data;
          if (
            createCategoryModal.value.data.contentType === null ||
            createCategoryModal.value.data.contentType === undefined
          ) {
            if (createCategoryModal.value.selectableContentType.length > 0) {
              createCategoryModal.value.data.contentType =
                createCategoryModal.value.selectableContentType[0].contentType;
            }
          }
          createCategoryModal.value.contentTypeLoading = false;
        })
        .catch(() => {
          message.error("无法获取可选资源类型");
        });
      post({
        url: "/api/authorization/client/getSelectable",
      })
        //eslint-disable-next-line
        .then((res: any) => {
          createCategoryModal.value.selectableRegistryClient = res.data;
          if (
            createCategoryModal.value.data.registryClient === null ||
            createCategoryModal.value.data.registryClient === undefined ||
            createCategoryModal.value.data.registryClient === ""
          ) {
            if (createCategoryModal.value.selectableRegistryClient.length > 0) {
              createCategoryModal.value.data.registryClient =
                createCategoryModal.value.selectableRegistryClient[0].clientId;
            }
          }
          createCategoryModal.value.clientLoading = false;
        })
        .catch(() => {
          message.error("无法获取可选内容来源");
        });
    };
    const updateCategoryModal = ref();
    const openUpdateCategoryModal = (record: CategoryItem) => {
      const categoryId = record.categoryId;
      updateCategoryModal.value.data.categoryId = record.categoryId;
      updateCategoryModal.value.data.categoryName = record.categoryName;
      updateCategoryModal.value.data.categorySymbol = record.categorySymbol;
      updateCategoryModal.value.data.contentType = record.contentType;
      updateCategoryModal.value.data.registryClient = record.registryClient;
      updateCategoryModal.value.data.parentId = record.parentId;
      updateCategoryModal.value.contentTypeLoading = true;
      updateCategoryModal.value.clientLoading = true;
      updateCategoryModal.value.parentCategoryLoading = true;
      updateCategoryModal.value.visible = true;
      post({
        url: "/api/content-engine/category/getSelectableContentType",
      })
        //eslint-disable-next-line
        .then((res: any) => {
          updateCategoryModal.value.selectableContentType = res.data;
          updateCategoryModal.value.contentTypeLoading = false;
        })
        .catch(() => {
          message.error("无法获取可选资源类型");
        });
      post({
        url: "/api/authorization/client/getSelectable",
      })
        //eslint-disable-next-line
        .then((res: any) => {
          updateCategoryModal.value.selectableRegistryClient = res.data;
          updateCategoryModal.value.clientLoading = false;
        })
        .catch(() => {
          message.error("无法获取可选内容来源");
        });
      post({
        url: "/api/content-engine/category/getSelectableParentCategory",
        data: {
          categoryId: categoryId,
        },
      })
        //eslint-disable-next-line
        .then((res: any) => {
          updateCategoryModal.value.selectableParentCategory = res.data;
          updateCategoryModal.value.parentCategoryLoading = false;
        })
        .catch(() => {
          message.error("无法获取可选上级分类");
        });
    };
    return {
      title,
      subtitle,
      paneKey,
      reloadTag,
      content,
      socketId,
      server,
      createCategoryModal,
      updateCategoryModal,
      openCreateCategoryModal,
      openUpdateCategoryModal,
    };
  },
  methods: {
    reload(value: boolean) {
      if (value) {
        this.content.init();
      }
    },
  },
  watch: {
    reloadTag: function (value) {
      this.reload(value);
    },
  },
});
