
import { defineComponent, reactive, ref } from "vue";
import { post } from "../../service/http";

export interface RegistryClient {
  registryClient: string;
  clientId: string;
}

export interface ContentType {
  name: string;
  contentType: number;
}

interface FormData {
  categoryName: string;
  categorySymbol: string;
  registryClient: string;
  contentType?: number;
  createById: string;
  parentId?: string;
  socketId: string;
}

export default defineComponent({
  props: ["socketId"],
  setup(props) {
    const visible = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const clientLoading = ref<boolean>(false);
    const contentTypeLoading = ref<boolean>(false);
    const createResult = ref<boolean>(false);
    const userId = localStorage.getItem("userId");
    const data = reactive<FormData>({
      categoryName: "",
      categorySymbol: "",
      registryClient: "",
      contentType: undefined,
      parentId: undefined,
      createById: userId === null ? "" : userId,
      socketId: props.socketId,
    });
    const selectableRegistryClient = ref<RegistryClient[]>([]);
    const selectableContentType = ref<ContentType[]>([]);
    return {
      visible,
      loading,
      createResult,
      clientLoading,
      contentTypeLoading,
      data,
      selectableRegistryClient,
      selectableContentType,
    };
  },
  methods: {
    reset() {
      this.data.categoryName = "";
      this.data.categorySymbol = "";
      this.loading = false;
      this.createResult = false;
      this.visible = false;
    },
    async createCategory() {
      this.loading = true;
      try {
        await post({
          url: "/api/content-engine/category/create",
          data: this.data,
        });
        this.reset();
      } catch (error) {
        this.loading = false;
        this.createResult = true;
      }
    },
  },
});
