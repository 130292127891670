
import { computed, defineComponent, ref } from "vue";
import { WebsocketCollapse, uuid, getId } from "bankezhan-framework-vue";
import { websocketServer, post } from "../../service/http";
import {
  DeploymentUnitOutlined,
  CloudUploadOutlined,
  UndoOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";

interface DataItem {
  key: string;
  categoryId: string;
  categoryName: string;
  children?: DataItem[];
}

const columns = [
  {
    key: "categoryName",
    dataIndex: "categoryName",
  },
];

export default defineComponent({
  components: {
    WebsocketCollapse,
    DeploymentUnitOutlined,
    CloudUploadOutlined,
    UndoOutlined,
    CloseCircleOutlined,
  },
  setup() {
    const socketId = uuid();
    const userId = localStorage.getItem("userId");
    const store = useStore();
    const panekey = store.state.activeKey;
    const articleId = getId(store.state, panekey, "articleId");
    const subtitle = getId(store.state, panekey, "title");
    const title = ref<string>("多点发布");
    const selectedRowKeys = ref<string[]>([]);
    const selectableCategory = ref<DataItem[]>([]);
    const loading = ref<boolean>(false);
    const defaultExpandedRowKeys = ref<string[]>(["CONTENT_ENGINE"]);
    const selectedCount = computed(() => {
      return selectedRowKeys.value.length;
    });
    return {
      websocketServer,
      socketId,
      articleId,
      title,
      subtitle,
      selectableCategory,
      columns,
      selectedRowKeys,
      loading,
      defaultExpandedRowKeys,
      selectedCount,
      userId,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getCategoryNavigator();
      this.getDefaultSelectedRowKeys();
    },
    addRowKey(key: string) {
      this.selectedRowKeys.push(key);
    },
    removeRowKey(key: string) {
      var index = this.selectedRowKeys.indexOf(key);
      if (index > -1) {
        this.selectedRowKeys.splice(index, 1);
      }
    },
    clearSelectedRowKeys() {
      this.selectedRowKeys = [];
    },
    resetSelectedRowKeys() {
      this.init();
    },
    async getDefaultSelectedRowKeys() {
      this.loading = true;
      try {
        const res = await post({
          url: "/api/content-engine/articleCategory/getCategoryOfArticle",
          data: {
            articleId: this.articleId,
            socketId: this.socketId,
          },
        });
        this.selectedRowKeys = res.data.slice();
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async getCategoryNavigator() {
      this.loading = true;
      try {
        const res = await post({
          url: "/api/content-engine/category/getCategoryNavigator",
          data: {
            contentType: 1,
          },
        });
        this.selectableCategory = res.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async updateCategoryOfArticle() {
      this.loading = true;
      const data = {
        articleId: this.articleId,
        categoryIdList: this.selectedRowKeys,
        updateById: this.userId,
        socketId: this.socketId,
      };
      try {
        await post({
          url: "/api/content-engine/articleCategory/updateCategoryOfArticle",
          data: data,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    onSelectChange(selectedRowKeys: string[]) {
      this.selectedRowKeys = selectedRowKeys;
    },
  },
});
